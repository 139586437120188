import React from 'react'
import ChatForm from './chat/ChatForm'

function Main() {
    return (
        <div className='main'>
            <ChatForm />
        </div>
    )
}

export default Main