import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from './ThemeContext';
import * as FaIcons from "react-icons/fa6";
import './toggle.css';

const ToggleBtn = () => {

    const { isDarkMode, toggleTheme } = useTheme();

    return (
    <>
            <div className="menu-item">
                <a className="footIcon" onClick={toggleTheme}>{isDarkMode ? <FaIcons.FaRegSun /> : <FaIcons.FaRegMoon />}</a>
                <span className="footIconText">Switch to<br /><strong>{isDarkMode ? 'Light Mode' : 'Dark Mode' }</strong></span>
            </div>
    </>
  );
};

export default ToggleBtn;