import React, { useState, useEffect } from 'react';

const MultiChoice = ({ options, onSelect }) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    onSelect(selected);
  }, [selected, onSelect]);

  const handleToggle = (option) => {
    setSelected(prev =>
      prev.includes(option)
        ? prev.filter(item => item !== option)
        : [...prev, option]
    );
  };

  return (
    <div className="choice-container">
      {options.map((option, index) => (
        <button
          key={index}
          onClick={() => handleToggle(option)}
          className={`choice-button ${selected.includes(option) ? 'selected' : ''}`}
        >
          {option}
        </button>
      ))}
    </div>
  );
};

export default MultiChoice;