import React from 'react';

const SingleChoice = ({ options, onSelect }) => {
  return (
    <div className="choice-container">
      {options.map((option, index) => (
        <button
          key={index}
          onClick={() => onSelect(option)}
          className="choice-button"
        >
          {option}
        </button>
      ))}
    </div>
  );
};

export default SingleChoice;