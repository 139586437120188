import React from 'react';

const ChatMessage = ({ message, type }) => {
  const renderContent = () => {
    if (typeof message === 'string' && message.startsWith('data:image')) {
      return <img src={message} alt="Uploaded" className="uploaded-image" />;
    } else if (Array.isArray(message)) {
      return message.join(', ');
    } else {
      return message;
    }
  };

  return (
    <div className={`chat-message ${type}`}>
      {renderContent()}
    </div>
  );
};

export default ChatMessage;