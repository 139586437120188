import React, { useState, useEffect, useRef } from 'react';
import * as FaIcons from "react-icons/fa6";
import TypeWriter from './TypeWriter';
import TextInput from './TextInput';
import SingleChoice from './SingleChoice';
import MultiChoice from './MultiChoice';
import ImageUpload from './ImageUpload';
import ChatMessage from './ChatMessage';
import questions from './questions.json';
import './ChatForm.css';

const languages = [
  { code: 'ma', name: 'मराठी' },
  { code: 'hi', name: 'हिंदी' },
  { code: 'en', name: 'English' }
];

const ChatForm = () => {
  const [language, setLanguage] = useState(null);
  const [step, setStep] = useState(-1);
  const [responses, setResponses] = useState([]);
  const [typing, setTyping] = useState(false);
  const [currentResponse, setCurrentResponse] = useState(null);
  const chatEndRef = useRef(null);

  useEffect(() => {
    if (language && step >= 0 && step < questions[language].length) {
      setTyping(true);
      const timer = setTimeout(() => setTyping(false), 1500);
      return () => clearTimeout(timer);
    }
  }, [step, language]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [responses, typing]);

  const handleLanguageSelect = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    setStep(0);
  };

  const handleResponse = (response) => {
    if (response !== undefined && response !== null) {
      setResponses([...responses, { question: questions[language][step].question, response }]);
      setCurrentResponse(null);
      if (step < questions[language].length - 1) {
        setStep(step + 1);
      } else {
        setStep(questions[language].length); // Set to length to indicate form completion
      }
    }
  };
  const handleCurrentResponse = (response) => {
    setCurrentResponse(response);
  };

  const renderInput = () => {
    const question = questions[language][step];
    switch (question.type) {
      case 'text':
        return <TextInput onSubmit={handleResponse} />;
      case 'single':
        return <SingleChoice options={question.options} onSelect={handleResponse} />;
      case 'multi':
        return (
          <>
            <MultiChoice options={question.options} onSelect={handleCurrentResponse} />
            <button 
              onClick={() => handleResponse(currentResponse)}
              className="send-button"
              disabled={!currentResponse || currentResponse.length === 0}
            >
              <FaIcons.FaArrowUp/>
            </button>
          </>
        );
      case 'image':
        return (
          <>
            <ImageUpload onUpload={handleCurrentResponse} />
            <button 
              onClick={() => handleResponse(currentResponse)}
              className="send-button"
              disabled={!currentResponse}
            >
              <FaIcons.FaArrowUp/>
            </button>
          </>
        );
      default:
        return null;
    }
  };

  if (!language) {
    return (
      <div className="language-selection">
        <div className="language-buttons">
          {languages.map((lang) => (
            <button
              key={lang.code}
              onClick={() => handleLanguageSelect(lang.code)}
              className="language-button"
            >
              {lang.name}
            </button>
          ))}
        </div>
        <h5>भाषा / Language</h5>
      </div>
    );
  }

  return (
    <div className="chat-container">
      <div className="top-space"></div>
      <div className="chat-messages">
        {responses.map((item, index) => (
          <React.Fragment key={index}>
            <ChatMessage message={item.question} type="question" />
            <ChatMessage message={item.response} type="response" />
          </React.Fragment>
        ))}
        {step < questions[language].length && (
          <>
            {!typing && <ChatMessage message={questions[language][step].question} type="question" />}
            {typing && <TypeWriter text={questions[language][step].question} />}
          </>
        )}
        <div ref={chatEndRef} />
      </div>
      <div className="chat-input">
        {step < questions[language].length ? (
          !typing && renderInput()
        ) : (
          <ChatMessage 
            message={
              language === 'en' ? "Thank you for having this chat!" :
              language === 'ma' ? "या छोट्या गप्पा मारल्याबद्दल धन्यवाद!" :
              "इस छोटी सी बातचीत के लिए धन्यवाद!"
            } 
            type="system" 
          />
        )}
      </div>
    </div>
  );
};

export default ChatForm;