import React from 'react'
import {BrowserRouter as Router, Routes, Route} from  'react-router-dom'
import Header from './header/Header';
import Footer from './footer/Footer';
import Home from './Main';
import './style.css'

function Index() {
    return (
        <Router>
            <Header />
            <Routes>
                <Route path='/' element={<Home />} />
            </Routes>
            {/* <Footer /> */}
        </Router>
    )
}

export default Index