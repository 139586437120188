import React, { useState } from 'react';
import * as FaIcons from "react-icons/fa6";

const ImageUpload = ({ onUpload }) => {
  const [preview, setPreview] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
        onUpload(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="image-upload-container">
      {preview && <img src={preview} alt="Preview" className="image-preview" />}
      {/* <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        className="file-input"
      /> */}

      <label className="filelabel">
          <FaIcons.FaPaperclip/> <span className="title">Add File</span>
          <input className="FileUpload1" accept="image/*" onChange={handleFileChange} id="FileInput" name="booking_attachment" type="file"/>
      </label>
    </div>
  );
};

export default ImageUpload;