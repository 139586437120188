import React from 'react'
import DevCred from './devcred/DevCred'
import './footer.css'
import GoToTop from '../assets/GoToTop';
import { ThemeProvider } from '../assets/toggle/ThemeContext';
import ToggleBtn from '../assets/toggle/ToggleBtn';

function Footer() {
    return (
        <div id='footer'>
            <DevCred />
            <GoToTop />
            <ThemeProvider>
                <ToggleBtn />
            </ThemeProvider>
        </div>
    )
}

export default Footer