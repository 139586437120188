import React, { useState, useEffect } from 'react';

const TypeWriter = ({ text }) => {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    let i = 0;
    const typing = setInterval(() => {
      if (i < text.length) {
        setDisplayText(prev => prev + text.charAt(i));
        i++;
      } else {
        clearInterval(typing);
      }
    }, 50);

    return () => clearInterval(typing);
  }, [text]);

  return <div className="chat-message typing">{displayText}</div>;
};

export default TypeWriter;