import React, { useState } from 'react';
import * as FaIcons from "react-icons/fa6";

const TextInput = ({ onSubmit }) => {
  const [text, setText] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (text.trim()) {
      onSubmit(text);
      setText('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="input-container">
      <input
        type="text"
        value={text}
        onChange={(e) => setText(e.target.value)}
        className="text-input"
        placeholder="Type your response..."
      />
      <button type="submit" className="send-button" disabled={!text.trim()}>
        <FaIcons.FaArrowUp/>
      </button>
    </form>
  );
};

export default TextInput;